import React from 'react';
import ImageSlider from './ImageSlider';
import Markdown from 'react-markdown';
import ProductGroup from './ProductGroup';
import ShippingInfo from './ShippingInfo';
import ProductNameAndPrice from './ProductNameAndPrice';
import DigitalProduct from './DigitalProduct';

function ExploreSingleProduct({
	images = [],
	product = {},
	groups = [],
	loading = false,
	openConfiguration = () => {},
	productIds = [],
	isDigitalProduct = false,
	openCartModal = () => {},
	standAlone = false,
}: {
	images: any[];
	product: any;
	groups: any[];
	loading: boolean;
	openConfiguration: () => void;
	productIds: any[] | undefined;
	isDigitalProduct: boolean;
	openCartModal: () => void;
	standAlone: boolean;
}) {
	return (
		<>
			<div className="flex-1">
				<ImageSlider images={images} />
			</div>
			<div className="flex flex-col gap-[30px] xl:mr-[70px] mr-[20px] md:max-w-[400px] xl:max-w-[523px] w-full xl:ml-[50px] ml-[20px] pt-[40px]">
				{!loading && (
					<>
						<div className="flex flex-col gap-[30px] ">
							<ProductNameAndPrice name={product?.seo?.metaTitle ?? product?.name} price={product?.commercialOptions?.price ?? ''} currency={product?.zone?.currency?.symbol} />
							<div className="flex flex-col gap-[16px] -mt-[14px]">
								{!!product?.description && (
									<p className="text-[14px] font-normal">
										<Markdown>{product?.description}</Markdown>
									</p>
								)}
							</div>
							<div className="h-[1px] bg-[#D4C4F6]" />
						</div>
						{groups?.length > 0 && (
							<div className="flex flex-col gap-[30px]">
								<p className="text-[20px] font-bold -mb-[14px]">Choose Options</p>
								<div className="flex flex-col gap-[30px]">
									{groups.map((group: any, index) => (
										<ProductGroup product={product} selectedOption={productIds[index]} allSelectedOptions={productIds} isColor={!!group?.options[0]?.color} key={group?.id ?? index} title={group.name} options={group.options} standAlone={standAlone} groupIndex={index} />
									))}
								</div>
								<div className="w-full min-h-[1px]  bg-[#D4C4F6]"></div>
							</div>
						)}
						{isDigitalProduct && <DigitalProduct product={product} openCartModal={openCartModal} />}
						{!isDigitalProduct && (
							<>
								<div>
									<ShippingInfo data={product?.zone?.shippingOptions ?? []} />
								</div>
								<div>
									<button className="uppercase flex flex-row h-[44px] items-center justify-center bg-[#21187F] rounded-full w-full text-center text-white" onClick={openConfiguration}>
										personalize this product
									</button>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
}

export default ExploreSingleProduct;
