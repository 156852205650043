import React, { useEffect, useState } from 'react';
import { ReactComponent as TrashIcon } from '../../assets/icons/TrashIcon.svg';
import { ReactComponent as DownArrowCart } from '../../assets/icons/DownArrowCart.svg';
import { createCustomerOrder, createOrder, getAvailablePayments, getAvailableShippingMethods, getCountries, getImage } from '../../server/server';
import CheckoutNotSignedIn from './CheckoutNotSignedIn';
import { useUser } from '../../auth/UseUser';
import CheckoutSignedIn from './CheckoutSignedIn';
import { useCart } from '../../hooks/useCart';
import { roundToDecimal, validateEmail, validatePhoneNumber } from '../../util/util';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import GreetingModal from './GreetingModal';
import Loading from '../../common/Loading';
import { Address } from 'cluster';
import { CurrencyID, MerchantID, TerminalID } from '../../Constants';

declare global {
	interface Window {
		UpcPayment: (options: { url: string; payment: any }) => void;
	}
}

function CartList({ mode, setMode }: { mode: any; setMode: any }) {
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const navigate = useNavigate();
	const { isSignedIn } = useUser();
	const { emptyCart, changeQuantity } = useCart();
	const [expandList, setExpandList] = useState(true);
	const { cart, removeFromCart } = useCart();
	const [note, setNote] = useState('');
	const [availableShippingMethods, setAvailableShippingMethods] = useState([]);
	const [availablePayments, setAvailablePayments] = useState([]);
	const [paymentMethod, setPaymentMethod] = useState<any>({});
	const [shippingMethod, setShippingMethod] = useState<any>({});
	const [creatingOrder, setCreatingOrder] = useState(false);
	const [modalOpen, setOpenModal] = useState(false);
	const [shippingInfo, setShippingInfo] = useState<any>({});
	const [shippingInfoError, setShippingInfoError] = useState<any>({
		firstName: false,
		lastName: false,
		email: false,
		address: false,
		countryId: false,
		city: false,
		phoneNumber: false,
		phoneNumberInvalid: false,
		zipCode: false,
		emailInvalid: false,
	});
	const [countries, setCountries] = useState([]);
	const [shippingAddress, setShippingAddress] = useState<any>(null);

	useEffect(() => {
		loadShippingMethods();
		loadPayingMethods();
		fetchLocationData();
	}, [cart]);

	const validate = () => {
		let valid = true;
		setShippingInfoError({ firstName: false, lastName: false, email: false, address: false, countryId: false, city: false, phoneNumber: false, zipCode: false, phoneNumberInvalid: false, emailInvalid: false });
		if (!shippingInfo?.firstName) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, firstName: true }));
		}
		if (!shippingInfo?.lastName) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, lastName: true }));
		}
		if (!shippingInfo?.email) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, email: true }));
		} else if (!validateEmail(shippingInfo?.email)) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, emailInvalid: true }));
		}
		if (!shippingInfo?.address) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, address: true }));
		}
		if (!shippingInfo?.countryId) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, countryId: true }));
		}
		if (!shippingInfo?.city) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, city: true }));
		}
		if (!shippingInfo?.phoneNumber) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, phoneNumber: true }));
		} else if (!validatePhoneNumber(shippingInfo?.phoneNumber)) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, phoneNumberInvalid: true }));
		}
		if (!shippingInfo?.zipCode) {
			valid = false;
			setShippingInfoError((p: any) => ({ ...p, zipCode: true }));
		}
		return valid;
	};

	const loadShippingMethods = async () => {
		const resp = await getAvailableShippingMethods(cart.map((e: any) => e.product.id));
		if (resp.status === 201) {
			setAvailableShippingMethods(resp.data);
			console.log(resp.data);

			setShippingMethod(resp.data[0]);
		}
	};

	const loadPayingMethods = async () => {
		const resp = await getAvailablePayments(cart.map((e: any) => e.product.id));
		if (resp.status === 201) {
			setAvailablePayments(resp.data);
			setPaymentMethod(resp.data[0]);
		}
	};
	useEffect(() => {
		const addScript = (src: string) => {
			const script = document.createElement('script');
			script.src = src;
			script.onload = () => setScriptLoaded(true);
			document.head.appendChild(script);
		};

		const addStylesheet = (href: string) => {
			const link = document.createElement('link');
			link.href = href;
			link.rel = 'stylesheet';
			document.head.appendChild(link);
		};

		addScript('https://ecconnect.upc.ua/pub/utils/upc-payment-sdk/upc-payment.js');
		addStylesheet('https://ecconnect.upc.ua/pub/utils/upc-payment-sdk/upc-payment.css');
	}, []);

	const openPayment = async (orderId: any, amount: any, desc: any, signature: string, time: number) => {
		if (scriptLoaded) {
			const paymentData = {
				Version: 1,
				MerchantID,
				TerminalID,
				locale: 'rs',
				Signature: signature,
				Currency: CurrencyID,
				TotalAmount: amount,
				PurchaseTime: time,
				OrderID: orderId,
				PurchaseDesc: desc,
			};
			window.UpcPayment({
				url: 'https://ecg.test.upc.ua/rbrs/enter',
				payment: paymentData,
			});
		} else {
			console.error('Payment script not loaded');
		}
	};

	function formatToTwoDecimals(num: number): number {
		return num * 100;
	}
	const clickOnCheckout = async () => {
		if (mode === 'checkout') {
			const resp = await submitOrder();
			if (resp === undefined) return;
			if (resp?.status == 201 && paymentMethod.provider === 'BANK_SERBIA') {
				openPayment(resp?.data?.orderNo, formatToTwoDecimals(resp?.data?.totalValue), resp?.data?.items?.map((i: any) => i.product.name).join(', ') || 'Layka order', resp?.data?.signature, resp?.data?.PurchaseTime);
			}
			setCreatingOrder(false);
			setOpenModal(true);
			setTimeout(() => {
				closeModal();
			}, 2000);
		} else {
			navigate('/cart/checkout');
			setMode('checkout');
		}
	};
	const makeOrder = async (data: any) => {
		//Posebne rute za kreiranje ordera za guesta i za signed in usera
		if (isSignedIn) {
			return createOrder({ ...data, shippingAddressId: shippingAddress?.id });
		} else {
			Object.assign(data, {
				customer: {
					firstName: shippingInfo.firstName,
					lastName: shippingInfo.lastName,
					email: shippingInfo.email,
					phoneNumber: shippingInfo.phoneNumber,
					countryId: shippingInfo.countryId,
					city: shippingInfo.city,
					street: shippingInfo.address,
					zipCode: shippingInfo.zipCode,
				},
			});
			return createCustomerOrder(data);
		}
	};
	const submitOrder = async () => {
		if (!isSignedIn && !validate()) return;
		setCreatingOrder(true);
		const data = {
			items: cart.map((item: any) => ({
				quantity: item.quantity,
				design: item.design,
				productId: item.product.id,
				productDesignId: !!item.chosenDesign ? item?.chosenDesign?.id : null,
				usedImageIds: item?.usedImageIds ?? [],
				previewImageId: item.imagePreviewId,
				digitalProductFields: item.digitalProductFields ?? [],
			})),
			shippingOption: shippingMethod?.id,
			paymentType: paymentMethod.id,
			note,
		};
		return await makeOrder(data);
	};

	const closeModal = () => {
		setOpenModal(false);
		emptyCart();
		navigate('/');
	};

	const _subTotal = cart.reduce((partialSum: number, item: any) => partialSum + (parseFloat(item?.product?.commercialOptions?.price) + (!!item.chosenDesign ? item.chosenDesign.price : 0)) * item.quantity, 0);
	const shippingCost = mode !== 'checkout' ? 0 : shippingMethod?.shipping_cost ?? 0;
	const totalCost = roundToDecimal(_subTotal + shippingCost);
	const subTotal = roundToDecimal(_subTotal);
	const currency = cart[0]?.product?.zone?.currency ?? {};

	const fetchLocationData = async () => {
		const countryResponse = await getCountries();
		if (countryResponse.status == 200) {
			setCountries(countryResponse.data);
		} else {
			alert(`Error fetching countries: ${countryResponse.status}`);
		}
	};

	const purchaseDisabled = mode === 'checkout' && isSignedIn && !shippingAddress?.id;
	return (
		<div className="w-full  mt-[57px] mb-[120px] ">
			<div className="w-full grid grid-cols-5 gap-5 mt-4">
				<div className="col-span-5 lg:col-span-3">
					<div className="flex flex-row mb-[13px] -mt-[41px]">
						<h3 className="text-[20px] leading-[28px] font-medium text-black flex-1">Items ({cart.length})</h3>
						{mode === 'checkout' && (
							<button onClick={() => setExpandList(!expandList)} className={`${expandList ? 'rotate-0' : ' rotate-180'}`}>
								<DownArrowCart />
							</button>
						)}
					</div>
					{expandList &&
						cart.map((item: any, index: number) => {
							const product = item?.product ?? {};

							const openProduct = () => {
								if (!!product?.optionIds) navigate(`/product/${product.urlSlug}--${product?.optionIds?.join(',')}`);
								else navigate(`/product/${product.urlSlug}--${product?.id}?standalone=true&editCart=${index}`);
							};

							const designPrice = item?.chosenDesign?.price ?? 0;
							const disabledLowerQuantity = item.quantity == 1;

							return (
								<div className="flex flex-row justify-between  bg-white p-10 rounded-[12px] mb-5">
									<div className="flex flex-row gap-5">
										<div className="w-[147px] h-[147px] bg-[#F1F1FE] rounded-[6px]">
											<img src={getImage(item?.imagePreviewId) ?? product.thumbnailId} className="w-[147px] h-[147px] object-cover rounded-md" alt={product?.name} title={product?.name} />
										</div>
										<div className="flex flex-col justify-center">
											<p className="text-[24px] leading-[30px] font-normal text-black ">{product?.name}</p>
											<div className="flex flex-row">
												<div className="flex flex-col">
													{!!product?.mappedGroups?.length && (
														<div className="mt-[10px]">
															{product?.mappedGroups?.map((group: any) => (
																<p className="text-[16px] leading-[24px] font-semibold text-black">
																	{group.name}: {group.options[0].name}
																</p>
															))}
														</div>
													)}
													<div className="flex flex-row items-center gap-[20px] mt-[10px]">
														<p className="text-[16px] leading-[24px] font-bold text-black ">
															{(parseFloat(product?.commercialOptions?.price) + designPrice).toFixed(2)} <span className="lowercase">{product?.zone?.currency?.symbol}</span>
														</p>
														<div className="flex flex-row items-center gap-[5px]">
															<span className="text-[16px]">Qty:</span>
															<div className="flex flex-row gap-[10px] px-[14px] h-[40px] bg-[#F1F1FE] items-center rounded-full">
																<button onClick={() => changeQuantity(index, -1)} disabled={disabledLowerQuantity}>
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<g clip-path="url(#clip0_2717_2039)">
																			<path d="M5 12H19" stroke={disabledLowerQuantity ? '#E5DDF6' : '#F89DBD'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																		</g>
																		<defs>
																			<clipPath id="clip0_2717_2039">
																				<rect width="24" height="24" fill="white" />
																			</clipPath>
																		</defs>
																	</svg>
																</button>
																<span>{item.quantity}</span>
																<button onClick={() => changeQuantity(index, 1)}>
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<g clip-path="url(#clip0_2717_2043)">
																			<path d="M12 5V19M5 12H19" stroke="#F89DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																		</g>
																		<defs>
																			<clipPath id="clip0_2717_2043">
																				<rect width="24" height="24" fill="white" />
																			</clipPath>
																		</defs>
																	</svg>
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="flex-1"></div>
											<button className="text-[16px] leading-[20px] font-semibold text-[#21187F] mt-[20px] text-left" onClick={openProduct}>
												Edit
											</button>
										</div>
									</div>
									<div className="flex flex-col items-center justify-center">
										<div className="p-[13px] cursor-pointer hover:bg-pink rounded-full duration-[230ms] ease-in-out transition-all" onClick={() => removeFromCart(index)}>
											<TrashIcon />
										</div>
									</div>
								</div>
							);
						})}

					{mode === 'checkout' && !isSignedIn && (
						<CheckoutNotSignedIn
							availablePayments={availablePayments}
							paymentMethod={paymentMethod}
							setPaymentMethod={setPaymentMethod}
							setShippingInfo={setShippingInfo}
							countries={countries}
							shippingInfoError={shippingInfoError}
							availableShippingMethods={availableShippingMethods}
							setShippingMethod={setShippingMethod}
							shippingMethod={shippingMethod}
						/>
					)}
					{mode === 'checkout' && isSignedIn && (
						<CheckoutSignedIn
							setShippingAddress={setShippingAddress}
							shippingAddress={shippingAddress}
							availablePayments={availablePayments}
							paymentMethod={paymentMethod}
							setPaymentMethod={setPaymentMethod}
							availableShippingMethods={availableShippingMethods}
							setShippingMethod={setShippingMethod}
							shippingMethod={shippingMethod}
						/>
					)}
				</div>
				<div className="col-span-5 lg:col-span-2 ">
					<div className="w-full flex flex-col bg-white rounded-xl p-5">
						<p className="text-[16px] leading-[24px] font-semibold text-black">Note:</p>
						<div className=" border-b border-b-black  h-[44px] w-full  flex flex-row items-center mt-3 ">
							<input className="text-[16px] leading-[20px] text-black bg-transparent w-full outline-none  placeholder:text-[#BBB7B4] " value={note} onChange={(e) => setNote(e.target.value)} placeholder="Enter note here" autoComplete="off" />
						</div>
						<div className="flex flex-row items-center justify-between mt-5">
							<p className="text-[16px] leading-[24px] font-semibold text-black">Subtotal</p>
							<p className="text-[18px] leading-[20px] font-normal text-black">
								{subTotal}
								<span className="lowercase">{currency?.symbol}</span>
							</p>
						</div>
						<div className="flex flex-row items-center justify-between mt-5">
							<p className="text-[16px] leading-[24px] font-semibold text-black">Delivery</p>
							{mode !== 'checkout' && <p className="text-[18px] leading-[20px] font-normal text-black">see at the checkout</p>}
							{mode === 'checkout' && (
								<p className="text-[18px] leading-[20px] font-normal text-black">
									{`${shippingMethod?.shipping_cost > 0 ? shippingMethod?.shipping_cost?.toFixed(2) : 'Free delivery'}`}
									{shippingMethod?.shipping_cost > 0 && <span className="lowercase">{currency?.symbol}</span>}
								</p>
							)}
						</div>
						<div className=" -ml-5 -mr-5 h-[1px] bg-laykaLightPurple mt-5"></div>
						<div className="flex flex-row items-center justify-between mt-5">
							<p className="text-[20px] leading-[28px] font-medium text-black">Total</p>
							<p className="text-[20px] leading-[28px] font-medium text-black">
								{totalCost}
								<span className="lowercase">{currency?.symbol}</span>
							</p>
						</div>
						<div className="flex flex-row items-center justify-end mt-[5px]">
							<p className="text-[14px] leading-[18px] font-normal text-black">Incl.Tax (20%)</p>
						</div>
						<div className=" -ml-5 -mr-5 h-[1px] bg-laykaLightPurple mt-5"></div>
						<button className={`w-full h-[44px] flex items-center justify-center rounded-[30px]  text-[14px] text-white font-bold mt-[30px] uppercase ${purchaseDisabled ? 'bg-[#DEDEDE]' : 'bg-laykaDarkPurple'}`} onClick={clickOnCheckout} disabled={creatingOrder || purchaseDisabled}>
							{creatingOrder ? <Loading height={20} /> : <>{mode === 'checkout' ? 'COMPLETE YOUR PURCHASE' : 'GO TO CHECKOUT'}</>}
						</button>
					</div>
				</div>
			</div>
			<GreetingModal modalIsOpen={modalOpen} closeModal={closeModal} />
		</div>
	);
}

export default CartList;
