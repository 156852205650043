import React, { useEffect, useState } from 'react';
import { getCountries, createCountries, getShippingZones, getImage } from '../../../server/server';
import axios from 'axios';
import { AnyARecord } from 'dns';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { useAppContext } from '../../../hooks/useAppContext';

interface SignIn {
	setSelectedMode: any;
	openModal: any;
}

function Country({ setSelectedMode, openModal }: SignIn) {
	const MemoizedLogo = React.memo(Logo);
	const { selectZone, zoneList } = useAppContext();
	const [countries, setCountries] = useState(zoneList);
	const [selectedCountry, setSelectedCountry] = useState<any>(null);

	useEffect(() => {
		setCountries(zoneList);
	}, [zoneList]);

	const submit = async () => {
		await selectZone(selectedCountry);
	};

	return (
		<div className="w-full h-full bg-white flex flex-col items-center justify-center py-10 ">
			<div className="flex flex-col max-w-[430px] w-full px-3  my-4 flex-1">
				<div className="mb-[40px]">
					<MemoizedLogo className="mx-auto" />
				</div>
				<p className="text-[30px] leading-[36px] text-black  font-bold  ">Choose your Zone</p>
				<p className="text-[18px] leading-[20px] text-black  font-normal  mt-5">To see available products</p>
				<div className="max-h-[calc(100%-280px)] overflow-auto flex flex-col mt-[30px] flex-1">
					{countries.map((country: any) => (
						<div className="flex flex-row items-center justify-between mb-[10px] p-4 cursor-pointer " key={country.id} onClick={() => setSelectedCountry(country)}>
							<div className="flex flex-row items-center gap-[10px]">
								<img src={getImage(country.icon.id)} alt={`${country.name} flag`} width="16" height="12" />
								<div className="text-[16px] leading-[20px] text-black  font-normal">{country.name}</div>
							</div>
							<div className="flex items-center justify-center border border-laykaDarkPurple min-h-[16px] min-w-[16px] rounded-full">{selectedCountry?.id === country?.id && <div className="min-w-[8px] min-h-[8px] bg-laykaDarkPurple rounded-full"></div>}</div>
						</div>
					))}
				</div>
				<div className={!!selectedCountry?.id ? 'opacity-100' : 'opacity-0'}>
					<button className="uppercase flex flex-row h-[44px] items-center justify-center bg-[#21187F] rounded-full w-full text-center text-white" onClick={submit}>
						Continue
					</button>
				</div>
			</div>
		</div>
	);
}

export default Country;
