import React, { createContext, useContext, useEffect, useState } from 'react';

const AppContext = createContext<any>(undefined);

const AppContextProvider = (props: any) => {
	const _zone = !!localStorage.getItem('zone') ? JSON.parse(localStorage.getItem('zone') ?? '{}') : null;

	const [zone, setZone] = useState<any>(_zone);
	const [zoneList, setZoneList] = useState<any[]>([]);

	const selectZone = (zone: any) => {
		console.log('Selcting zone', zone);
		localStorage.setItem('zone', JSON.stringify(zone));
		setZone(zone);
		console.log('Finished...');
	};

	return <AppContext.Provider value={{ zone, selectZone, zoneList, setZoneList }} {...props} />;
};

function useAppContext() {
	const context = useContext(AppContext);

	if (context === undefined) {
		throw new Error(`useAppContext must be used within a AppContextProvider`);
	}

	return context;
}

export { AppContextProvider, useAppContext };
