import React, { useEffect, useState } from 'react';
import { getOrderByOrderId, getOrderItemsByOrderId } from '../../../../server/server';
import { Order as OrderInterface, OrderItem, OrderStatusEnum } from './OrderInterfaces';
import { useUser } from '../../../../auth/UseUser';
import { useParams } from 'react-router';
import { roundToDecimal } from '../../../../util/util';

const Order = () => {
	const [order, setOrder] = useState<OrderInterface>();
	const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

	const { user, setUser, isSignedIn } = useUser();

	const { selectedOrderId } = useParams();

	useEffect(() => {
		fetchOrder();
	}, [selectedOrderId]);

	const fetchOrder = async () => {
		const resp = await getOrderByOrderId(selectedOrderId);

		if (resp.status == 200) {
			console.log(resp.data);

			setOrder(resp.data);
			setOrderItems(resp.data?.products);
		}
	};

	const mapShippingStatus = (status: any): string => {
		return status !== undefined ? OrderStatusEnum[status] || '' : '';
	};

	const mapShippingStatusColor = (status: any): string => {
		switch (status) {
			case 0:
			case 1:
			case 5:
				return '#FFAD05'; //created, processing, refunded
			case 2:
			case 7:
			case 6:
				return '#F54126'; // on hold, cancelled, refunded
			case 3:
			case 4:
				return '#4DB597'; // shipped, delivered
			default:
				return '';
		}
	};

	return (
		<div className="">
			<div>
				<div className="mb-[20px]">
					<h4 className="font-bold text-[30px] font-notoSans leading-9 tracking-normal text-left mb-[20px] " title={order?.id}>
						Order #{order?.id}
					</h4>
				</div>
				<div className="mb-[10px]">
					<span className="text-[16px] font-semibold leading-6 text-center font-notoSans">Tracking number: </span>
					<span className="text-[16px] font-normal leading-6 text-center font-notoSans">{order?.orderTrackingCode}</span>
				</div>
				<div className="mb-[10px]">
					<span className="text-[16px] font-semibold leading-6 text-center font-notoSans">Placed: </span>
					<span className="text-[16px] font-normal leading-6 text-center font-notoSans">{order?.createdAt && new Date(order?.createdAt).toLocaleDateString('en-GB')}</span>
				</div>
				<div className="mb-[30px]">
					<span className="text-[16px] font-semibold leading-6 text-center font-notoSans">Status: </span>
					<span className={`text-[16px] font-normal leading-6 text-center font-notoSans text-${mapShippingStatusColor(order?.shippingStatus)} `} style={{ color: mapShippingStatusColor(order?.shippingStatus) }}>
						{mapShippingStatus(order?.shippingStatus)}
					</span>
				</div>
			</div>
			<div className="rounded-[12px] pt-[40px] bg-white w-[630px]">
				<div className="flex flex-row ">
					<div className="ml-[40px] ">
						<h6 className="text-[20px] font-medium leading-6 text-left w-[178px] mr-[150px] font-notoSans">Product</h6>
					</div>
					<div className="mr-[84px]">
						<h6 className="text-[20px] font-normal leading-6 text-left font-notoSans">Subtotal</h6>
					</div>
				</div>
				<div className="mx-[40px] w-[479px] my-[20px] bg-laykaLight h-[1px]"></div>
				<div className="flex flex-col gap-[10px]">
					{orderItems?.map((orderItem: any, index: any) => {
						console.log(orderItem);

						const designPrice = orderItem?.orderDesign?.price ?? 0;
						return (
							<div key={index} className="flex flex-row ml-[40px] mr-[80px]">
								<div className="">
									<p className="font-semibold text-base leading-6 text-left w-[178px] mr-[150px] font-notoSans">{orderItem?.product?.name}</p>
								</div>
								<div>
									<p className="font-semibold text-base leading-6 text-left font-notoSans">
										{roundToDecimal(parseFloat(orderItem?.product?.commercialOptions?.price) + designPrice)} {order?.currency?.symbol}
									</p>
								</div>
							</div>
						);
					})}
				</div>

				<div className="mx-[40px] w-[479px] my-[20px] bg-laykaLight h-[1px]"></div>

				<div className="flex flex-row mx-[40px] mr-[80px] pb-[20px]">
					<div className="mb-[10px]">
						<h6 className="text-[20px] font-medium w-[178px] mr-[150px] leading-6 text-left font-notoSans text-laykaDark">Total</h6>
					</div>
					<div>
						<h6 className="text-[20px] font-medium leading-6 text-left font-notoSans text-laykaDark">
							{order?.totalValue} {order?.currency?.symbol}
						</h6>
					</div>
				</div>
			</div>
			<div className="flex flex-row mt-[50px]">
				{/* Shipping Address */}
				<div>
					<h6 className="font-bold text-[20px] font-notoSans leading-[28px] tracking-normal text-left mb-[20px]">Shipping Address:</h6>
					<div className="text-[16px] font-normal leading-6 text-left font-notoSans">
						{/* {order?.shippingAddress?.customer?.firstName} {order?.shippingAddress?.customer?.lastName} */}
						<p className="text-[16px] mb-[10px] font-normal leading-6 text-left font-notoSans">
							{user?.firstName} {user?.lastName}
						</p>

						<p className="text-[16px] mb-[10px] font-normal leading-6 text-left font-notoSans">{order?.shippingAddress?.street}</p>

						<p className="text-[16px] mb-[10px] font-normal leading-6 text-left font-notoSans">{order?.shippingAddress?.city}</p>

						<p className="text-[16px] mb-[10px] font-normal leading-6 text-left font-notoSans">{order?.shippingAddress?.country.name}</p>

						<p className="text-[16px] mb-[10px] font-normal leading-6 text-left font-notoSans">{order?.shippingAddress?.zip}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Order;
