import { useEffect, useState } from 'react';
import { fabric } from 'fabric';
export const getEdgeDots = (obj: any) => {
	const myLeft = Math.min(obj.aCoords.bl.x, obj.aCoords.br.x, obj.aCoords.tl.x, obj.aCoords.tr.x);
	return { myLeft };
};

type objProps = {};

type pointProps = {
	x: number;
	y: number;
};
export const calculateAngleBetweenTwoVectors = (anchor: pointProps, point1: pointProps, point2: pointProps) => {
	// Anuliramo koordinate
	const a = {
		x: point1.x - anchor.x,
		y: point1.y - anchor.y,
	};
	const b = {
		x: point2.x - anchor.x,
		y: point2.y - anchor.y,
	};
	const ab = a.x * b.x + a.y * b.y;
	const aa = Math.sqrt(a.x * a.x + a.y * a.y);
	const bb = Math.sqrt(b.x * b.x + b.y * b.y);
	return (Math.acos(ab / (aa * bb)) * 180) / Math.PI;
};

export const getIntersectionPointsWithVertical = (centerPoint: pointProps, x0: number, r: number) => {
	const xPow = Math.pow(centerPoint.x - x0, 2);
	const sub = Math.abs(r * r - xPow);
	const sqrt = Math.sqrt(sub);
	const y_prim = Math.abs(sqrt);
	const y1 = y_prim + centerPoint.y;
	const y2 = -1 * y_prim + centerPoint.y;
	const point1 = {
		x: x0,
		y: y1,
	};
	const point2 = {
		x: x0,
		y: y2,
	};
	return { point1, point2 };
};

export const getIntersectionPointsWithHorizontal = (centerPoint: pointProps, y0: number, r: number) => {
	const yPow = Math.pow(centerPoint.y - y0, 2);
	const sub = Math.abs(r * r - yPow);
	const sqrt = Math.sqrt(sub);
	const x_prim = Math.abs(sqrt);
	const x1 = x_prim + centerPoint.x;
	const x2 = -1 * x_prim + centerPoint.x;
	const point1 = {
		x: x1,
		y: y0,
	};
	const point2 = {
		x: x2,
		y: y0,
	};
	return { point1, point2 };
};

export const findEdgePoint = (points: pointProps[], coordinate: 'x' | 'y', order: 'lower' | 'higher') => {
	const orderNumber = order === 'lower' ? 1 : -1;
	points.sort((a, b) => (a[coordinate] > b[coordinate] ? orderNumber : -1 * orderNumber));
	return points[0];
};

export const findCloser = (rootPoint: pointProps, point1: pointProps, point2: pointProps, coordinate: 'x' | 'y') => {
	const d1 = Math.abs(rootPoint[coordinate] - point1[coordinate]);
	const d2 = Math.abs(rootPoint[coordinate] - point2[coordinate]);
	return d1 < d2 ? point1 : point2;
};

export const roundToDecimal = (number: number, decimal: number = 2) => parseFloat(`${number}`).toFixed(decimal);

export const debounce = <T extends (...args: any[]) => any>(func: T, delay: number): ((...funcArgs: Parameters<T>) => void) => {
	let inDebounce: NodeJS.Timeout | null;
	return function (this: any, ...args: Parameters<T>) {
		if (inDebounce) clearTimeout(inDebounce);
		inDebounce = setTimeout(() => func.apply(this, args), delay);
	};
};

export const dataUrlToFile = (url: string) => {
	const arr: any = url.split(',');
	const mime = arr[0]?.match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	const blob = new Blob([u8arr], { type: mime });
	return new File([blob], 'slika.png', { type: 'image/png' });
};

export const useContainerDimensions = (myRef: any) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const getDimensions = () => ({
			width: myRef.current.offsetWidth,
			height: myRef.current.offsetHeight,
		});

		const handleResize = () => {
			setDimensions(getDimensions());
		};

		if (myRef.current) {
			setDimensions(getDimensions());
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [myRef]);

	return dimensions;
};

export const validatePhoneNumber = (phoneNumber?: string) => {
	const regex = /^(\+?\d{2,3})?(\d{3}\s?\d{3}\s?\d{3,4})$/;
	return phoneNumber ? regex.test(phoneNumber) : false;
};
export const validateEmail = (email: string) => {
	const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return regex.test(email);
};

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTextWithNewLines = (textbox: fabric.Textbox, applyGrapheme = false) => {
	const text = (textbox as fabric.Textbox).text ?? '';
	const realRows = text.split('\n');
	const realRowsSpited = realRows.map((e) => e.split(applyGrapheme ? '' : ' '));
	let rows = [];
	for (let row of realRowsSpited) {
		let tempRow: string[] = [];
		for (let word of row) {
			tempRow.push(word);
			const singleLine = tempRow.join(applyGrapheme ? '' : ' ');
			const tempTextbox: fabric.Textbox = new fabric.Textbox(singleLine, textbox);

			tempTextbox.set('splitByGrapheme', applyGrapheme);

			tempTextbox.set('text', singleLine);
			tempTextbox.setCoords();
			tempTextbox.initDimensions();
			const lines = (tempTextbox as any)?._textLines?.length;

			if (lines > 1) {
				tempRow.pop();
				rows.push(tempRow.join(' '));
				tempRow = [word];
			}
		}
		rows.push(tempRow.join(' '));
	}
	return rows.join('\n');
};
