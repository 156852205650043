import React from 'react';
import { ReactComponent as TechIcon } from '../../assets/icons/TechIcon.svg';

function TechnicalSpecs({ specs = ['10x12cm', '10x12cm', '10x12cm', '10x12cm'] }: { specs?: any[] }) {
	return (
		<div className="flex flex-col items-center">
			<div className="text-center text-[30px] font-normal">Technical specs</div>
			<div className="mt-[46px] mx-auto flex flex-row gap-[70px] self-center">
				{specs.map((spec: any, index) => (
					<div key={index}>
						<TechIcon />
						<div className="mt-[16px] font-medium text-[20px]">{spec}</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default TechnicalSpecs;
