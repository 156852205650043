import React, { useEffect, useState } from 'react';
import { NoOrders } from '../../SVG/NoOrdersSvg';
import { useNavigate } from 'react-router-dom';
import { getCustomerByUserId, getMyOrders } from '../../../../server/server';
import { OrderStatusEnum } from './OrderInterfaces';
import { useUser } from '../../../../auth/UseUser';

interface Order {
	id: string;
	createdAt: string;
	orderTrackingCode: string;
	totalValue: string;
	shippingStatus: string;
}

const Orders = () => {
	const [orders, setOrders] = useState<Order[]>([]);
	const [customer, setCustomer] = useState<any>();

	const { user, setUser, isSignedIn } = useUser();

	// const dummyOrders = [
	// 	{
	// 		id: '132432434432',
	// 		createdAt: '2024-02-01T10:30:00Z',
	// 		orderTrackingCode: 'iwekd343e88',
	// 		totalValue: '50.00',
	// 		shippingStatus: '3', // Change this to a string
	// 	},
	// 	{
	// 		id: '232432423432432543',
	// 		createdAt: '2024-02-02T14:45:00Z',
	// 		orderTrackingCode: 'iwekd343e88',
	// 		totalValue: '75.50',
	// 		shippingStatus: '1', // Change this to a string
	// 	},
	// 	{
	// 		id: '343543643634',
	// 		createdAt: '2024-02-03T09:15:00Z',
	// 		orderTrackingCode: 'iwekd343e88',
	// 		totalValue: '30.25',
	// 		shippingStatus: '2', // Change this to a string
	// 	},
	// ];

	useEffect(() => {
		if (isSignedIn) {
			// fetchCustomer();
		}
	}, [user.id, isSignedIn]);

	useEffect(() => {
		fetchOrders();
	}, [customer]);

	// const fetchCustomer = async () => {
	// 	const resp = await getCustomerByUserId();
	// 	console.log(resp);
	// 	if (resp.status == 200) {
	// 		setCustomer(resp.data);
	// 		fetchOrders(resp.data.id);
	// 	} else {
	// 		alert(`Error fetching user information: ${resp.status}`);
	// 	}
	// };

	const fetchOrders = async () => {
		const resp = await getMyOrders();

		if (resp.status == 200) {
			setOrders(resp.data);
		}
	};

	const mapShippingStatus = (status: any): string => {
		return status !== undefined ? OrderStatusEnum[status] || '' : '';
	};

	const mapShippingStatusColor = (status: any): string => {
		console.log('status:', status);
		switch (status) {
			case 0:
			case 1:
			case 5:
				return '#FFAD05'; //created, processing, refunded
			case 2:
			case 7:
			case 6:
				return '#F54126'; // on hold, cancelled, refunded
			case 3:
			case 4:
				return '#4DB597'; // shipped, delivered
			default:
				return '';
		}
	};

	const navigate = useNavigate();
	return (
		<div className="w-full lg:pr-[40px]">
			{orders.length > 0  ? (
				<div>
					<div>
						<h4 className="font-bold font-notoSans leading-[36px] text-[30px] tracking-normal text-left mb-[18px]">My orders</h4>
					</div>
					<div className="bg-gray-200 px-[20px] mb-[18px]">
						<div className="grid grid-cols-7 gap-4">
							<p className="text-[16px] font-notoSans font-semibold leading-[24px] text-left col-span-1">Order</p>
							<p className="text-[16px] font-notoSans font-semibold leading-[24px] text-left col-span-1">Date</p>
							<p className="text-[16px] font-notoSans font-semibold leading-[24px] text-left col-span-2 whitespace-nowrap">Tracking Number</p>
							<p className="text-[16px] font-notoSans font-semibold leading-[24px] text-left col-span-1">Total</p>
							<p className="text-[16px] font-notoSans font-semibold leading-[24px] text-left col-span-1">Status</p>
							<p className="text-[16px] font-notoSans font-semibold leading-[24px] text-center col-span-1 whitespace-nowrap">Actions</p>
						</div>
					</div>

					{orders.map((order) => (
						<div key={order?.id} className="bg-white rounded-[12px] p-4 mb-[20px]">
							<div className="grid grid-cols-7 gap-4 items-center">
								<p className="text-[14px] font-notoSans font-normal leading-[18px] text-left col-span-1" title={order.id}>
									{' '}
									#{order?.id.slice(0, 5)}
									{order?.id.length > 5 ? '...' : ''}
								</p>
								<p className="text-[14px] font-notoSans font-normal leading-[18px] text-left col-span-1 ">
									{order?.createdAt &&
										new Date(order.createdAt)
											.toLocaleDateString('en-GB', {
												day: '2-digit',
												month: '2-digit',
												year: 'numeric',
											})
											.replace(/\//g, '-')}
								</p>
								<p className="text-[14px] font-notoSans font-normal leading-[18px] text-left col-span-2">{order?.orderTrackingCode}</p>
								<p className="text-[14px] font-notoSans font-normal leading-[18px] text-left col-span-1">{order?.totalValue}</p>
								<p className="text-[14px] font-notoSans font-normal leading-[18px] text-left col-span-1" style={{ color: mapShippingStatusColor(order?.shippingStatus) }}>
									{' '}
									{mapShippingStatus(order?.shippingStatus)}
								</p>
								<button
									className="bg-blue-500 hover:bg-blue-700 text-laykaDark font-bold py-2 px-4 rounded text-center"
									onClick={() => {
										navigate(`/profile/order/${order.id}`);
									}}
								>
									View
								</button>
							</div>
						</div>
					))}
				</div>
			) : (
				// Show message when there are no orders
				<div className="flex flex-col items-center justify-center w-full">
					<NoOrders />
					<h4 className="font-bold text-3xl font-notoSans leading-[36px] tracking-normal text-center mb-4">There are no orders yet...</h4>
					<p className="max-w-[429px] mb-[30px] text-[14px] leading-[24px] font-notoSans text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </p>
					<button className="w-[343px] h-[44px] border rounded-[30px] text-white text-base font-bold leading-5 text-center font-noto-sans bg-laykaPurple mb-4" onClick={() => navigate('/products')}>
						EXPLORE PRODUCTS
					</button>
				</div>
			)}
		</div>
	);
};

export default Orders;
