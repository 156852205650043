export interface BillingAddress {
	id: string;
	fullName: string;
	street: string;
	number: string;
	zip: string;
	vatNumber: string;
	phone?: string;
	isDefault: boolean;
	disabled: boolean;
	deletedAt: Date | null;
	comment: string | null;
	country: Country;
	region: Region;
	municipality: Municipality;
	city: string;
	customer: Customer;
}
export interface ShippingAddress {
	id: string;
	street: string;
	number: string;
	zip: string;
	vatNumber: string;
	phone?: string;
	isDefault: boolean;
	disabled: boolean;
	deletedAt: Date | null;
	comment: string | null;
	country: Country;
	region: Region;
	municipality: Municipality;
	city: string;
	customer: Customer;
}

export interface Address {
	id: string;
	firstName: string;
	lastName: string;
	city: string;
	country: Country | null;
	municipality: Municipality;
	region: Region;
	street: string;
	vatNumber: string;
	zip: string;
	phone: string;
	number: string;
	cityId?: string;
	isDefault: boolean;
}

export interface Currency {
	id: string;
	name: string;
	symbol: string;
	disabled: boolean;
	deletedAt: Date | null;
}

export interface User {
	id: string;
	email: string;
}

export interface Customer {
	id: string;
	firstName: string;
	lastName: string;
	user: User;
	company: string;
	disabled: boolean;
	deletedAt: Date | null;
}

export interface PaymentType {
	id: string;
	name: string;
	description: string;
	priority: number;
	disabled: boolean;
	deletedAt: Date | null;
}

export interface ShippingOption {
	id: string;
	name: string;
	description: string;
	ordinal: number;
	shippingCost: number;
	disabled: boolean;
	deletedAt: Date | null;
}

export interface Zone {
	id: string;
	zoneName: string;
	disabled: boolean;
	deletedAt: Date | null;
}

export interface Order {
	id: string;
	billingAddress: BillingAddress;
	comments: OrderComment[];
	createdAt: Date;
	currency: Currency;
	customer: Customer;
	differentAddress: boolean;
	disabled: boolean;
	fulfilledAt: Date | null;
	isPaid: boolean;
	orderItems: OrderItem[];
	numberOfItems: number;
	orderStatus: OrderStatusEnum;
	orderTrackingCode: string;
	origin: string;
	paymentReference: string;
	paymentStatus: PaymentStatusEnum;
	paymentType: PaymentType;
	shippingAddress: ShippingAddress;
	shippingCost: string;
	shippingOption: ShippingOption;
	shippingStatus: ShippingStatusEnum;
	totalTax: number;
	totalValue: number;
	vatNo: string;
	statusLogs: StatusLog[];
}

export interface OrderComment {
	id: string;
	commentText: string;
	commentedAt: string;
	isPrivate: boolean;
	createdBy: string;
	orderId: string;
}

export interface OrderItem {
	id: string;
	order: Order;
	orderDesign: ProductDesign;
	sku: string;
	products: Product;
	quantity: number;
	cost: number;
	regularPrice: number;
	tax: string;
	itemTotal: number;
	disabled: boolean;
}

export interface StatusLog {
	id: string;
	user: User;
	oldStatus: OrderStatusEnum;
	newStatus: OrderStatusEnum;
	order: Order;
	date: Date;
}

export enum OrderStatusEnum {
	'CREATED',
	'PROCESSING',
	'ON HOLD',
	'SHIPPED',
	'DELIVERED',
	'RETURNED',
	'REFUNDED',
	'CANCELLED',
}

export enum PaymentStatusEnum {
	'PAID IN FULL',
	'PAID PARTIALLY',
	'NOT PAID',
	'REFUNDED',
}

export enum ShippingStatusEnum {
	'PROCESSING',
	'SHIPPED',
	'RETURNED',
	'CANCELLED',
	'REFUNDED',
}

export interface Country {
	id: string;
	name: string;
}
export interface Region {
	id: string;
	country: Country;
	name: string;
}
export interface Municipality {
	id: string;
	region: Region;
	name: string;
}
export interface City {
	id: string;
	municipality: Municipality;
	name: string;
	zip_code: string;
}

interface Product {
	name: string;
	sku: string;
	price: number;
	category: string;
	created: Date;
}

interface ProductDesign {
	designName: string;
	price: number;
	currency: Currency;
	iconSmall: string;
	image: string;
	categories?: any[];
	layers?: any[];
}
